import React from "react";
import _ from "lodash";
import { setParams, navigateTo } from "../../utils/location";
import AppContainer from "../AppContainer";
import List from "../List";
import Item from "../Item";
import Button from "../Button";
import GiftEmptyStateIcon from "../icons/Gift.svg";
import styles from "./index.module.scss";
import "rc-steps/assets/index.css";
import "rc-steps/assets/iconfont.css";
const page = require("scroll-doc")();
import scroll from "scroll";
import { getCoupon, isPointBankCoupon } from "../../utils/gifts";
import { getAppMedia, mediaTypes } from "../../utils/media";

import { STEP } from "../../store/giftRedeem/reducer";

export default class GiftView extends React.PureComponent {
  state = {
    menuItemClicked: null,
  };

  componentDidMount() {
    scroll.top(page, 0);
  }

  onSelectedItem = (item) => () => {
    this.setState({ menuItemClicked: item.id });
    const { saveSelectedGiftStep, giftRedeem, pageContext } = this.props;
    saveSelectedGiftStep(item, pageContext.menuData);

    const nextStep = giftRedeem.steps[giftRedeem.currentStepIndex + 1];
    console.log("nextStep", nextStep);
    if (nextStep) {
      navigateTo(
        setParams(nextStep.context.pathname, { stepIndex: nextStep.index }),
      );
    } else {
      navigateTo("/order");
    }
  };

  tradeBankPointsForCouponIfNeededAndGoToOrder = () => {
    const { giftRedeem, tradeBankPointsForCoupon } = this.props;
    if (isPointBankCoupon(giftRedeem.selectedGift)) {
      tradeBankPointsForCoupon(giftRedeem.selectedGift);
    }
    navigateTo("/order");
  };

  componentDidUpdate(prevProps) {
    const currentPathName = _.endsWith(this.props.location.pathname, "/")
      ? this.props.location.pathname.slice(0, -1)
      : this.props.location.pathname;

    if (
      this.props.giftRedeem.shouldNavigateTo &&
      prevProps.giftRedeem.isDuringDealRedeem &&
      prevProps.giftRedeem.shouldNavigateTo !== this.props.shouldNavigateTo &&
      this.props.shouldNavigateTo !== currentPathName
    ) {
      console.log("component did update in gift view issue");
      // navigateTo(this.props.giftRedeem.shouldNavigateTo);
    }
  }

  render() {
    const {
      pageContext: {
        menuData,
        business: { currencySymbol, allergens },
      },
      appStyles,
      order,
      dealGroup,
      giftRedeem,
      servingOptionDetails,
      T,
    } = this.props;

    if (!(giftRedeem.isDuringGiftRedeem || giftRedeem.isDuringDealRedeem)) {
      return null;
    }
    const currentStep = giftRedeem.steps[giftRedeem.currentStepIndex];
    if (!currentStep) {
      return null;
    }

    const coupon = getCoupon(giftRedeem.selectedGift);

    if (currentStep.type === STEP.MONEY_GIFT) {
      const maxAmount = (
        _.get(coupon, "couponValue.offeredMoneyAmount") || 0
      ).toFixed(2);
      const minAmount = (
        _.get(coupon, "couponValue.condition.moneyAmount") || 0
      ).toFixed(2);
      return (
        <AppContainer.Content
          classNames={styles.AppContainer}
          tightBottom
          appStyles={appStyles}
        >
          <AppContainer.CenteredColumn>
            <div style={{ marginTop: 20, color: "#D9D9D9" }}>
              <GiftEmptyStateIcon />
            </div>
            <h2>{coupon.title || ""}</h2>
            <p>{coupon.description || ""} </p>
            <div
              style={{
                marginTop: 12,
                color: appStyles.titleOnBackgroundColor,
                fontSize: "2rem",
              }}
            >
              {currencySymbol}
              {maxAmount}
            </div>
            <div
              style={{
                marginTop: 12,
                fontSize: "1rem",
                color: appStyles.titleOnBackgroundColor,
              }}
            >
              {minAmount > 1.0
                ? `${T("Get up to")} ${currencySymbol}${maxAmount} ${T(
                    "discount on any order over",
                  )} ${currencySymbol}${minAmount}`
                : `${T("Get up to")} ${currencySymbol}${maxAmount} ${T(
                    "discount on your order",
                  )}`}
            </div>
            <div
              style={{
                fontSize: "0.8rem",
                color: appStyles.actionColor,
                marginTop: 32,
              }}
            >
              {T("* This reward is for one time use only")}
            </div>
          </AppContainer.CenteredColumn>
          <AppContainer.CenteredColumn style={{ marginTop: 20 }}>
            <Button
              appStyles={appStyles}
              onClick={this.tradeBankPointsForCouponIfNeededAndGoToOrder}
            >
              {T("Redeem and Order!")}
            </Button>
          </AppContainer.CenteredColumn>
        </AppContainer.Content>
      );
    }

    if (currentStep.type === STEP.GLOBAL_DISCOUNT_GIFT) {
      const discountPercent = (
        _.get(coupon, "couponValue.discountPercent") || 0
      ).toFixed(2);
      const minAmount = (
        _.get(coupon, "couponValue.condition.moneyAmount") || 0
      ).toFixed(2);
      return (
        <AppContainer.Content
          classNames={styles.AppContainer}
          tightBottom
          appStyles={appStyles}
        >
          <AppContainer.CenteredColumn>
            <div style={{ marginTop: 20, color: "#D9D9D9" }}>
              <GiftEmptyStateIcon />
            </div>
            <h2>{coupon.title || ""}</h2>
            <p>{coupon.description || ""} </p>
            <div
              style={{
                marginTop: 12,
                color: appStyles.accentColor,
                fontSize: "2rem",
              }}
            >
              {discountPercent}%
            </div>
            <div
              style={{
                marginTop: 12,
                fontSize: "1rem",
                color: appStyles.actionColor,
              }}
            >
              {minAmount > 1.0
                ? `${T("Get")} ${discountPercent}% ${T(
                    "discount on any order over",
                  )} ${currencySymbol}${minAmount}`
                : `${T("Get")} ${discountPercent}% ${T(
                    "discount on your order",
                  )}`}
            </div>
            <div
              style={{
                fontSize: "0.8rem",
                color: appStyles.actionColor,
                marginTop: 32,
              }}
            >
              {T("* This reward is for one time use only")}
            </div>
          </AppContainer.CenteredColumn>
          <AppContainer.CenteredColumn style={{ marginTop: 20 }}>
            <Button
              appStyles={appStyles}
              onClick={this.tradeBankPointsForCouponIfNeededAndGoToOrder}
            >
              {T("Redeem and Order!")}
            </Button>
          </AppContainer.CenteredColumn>
        </AppContainer.Content>
      );
    }

    const defaultImage = getAppMedia(mediaTypes.defaultItemImage, appStyles);

    if (currentStep.type === STEP.SELECT_DEAL_OPTION) {
      return (
        <AppContainer.Content
          classNames={styles.AppContainer}
          tightBottom
          appStyles={appStyles}
        >
          <List classNames={styles.GiftOptions}>
            {_.map(dealGroup, (deal) => (
              <Item
                T={T}
                appStyles={appStyles}
                key={deal.id}
                title={deal.description}
                onClick={this.onSelectedItem(deal)}
                imagePreview={deal.imagePreview}
                imageKey={deal.imageKey}
                defaultImage={defaultImage}
                classNames={styles.GiftOption}
                loading={this.state.menuItemClicked === deal.id}
                isDeal
                currencySymbol={currencySymbol}
              />
            ))}
          </List>
        </AppContainer.Content>
      );
    }

    const menuItemIds = _.flatMap(currentStep.context.menuItems, "menuItemId");

    const categoriesToFilter = [
      "5bc454afc04d8858d643cc5b",
      "5bb9b87ac04d88316b1f118a",
      "5bb9b87ac04d88316b1f118b",
      "5bb9b87fc04d88316b1f11bb",
      "5bb9b891c04d88316b1f1229",
      "5bb9b89ec04d88316b1f1268",
      "5bd700d6a6aed81e701e5bee",
      "5bc88efdc04d88416230b907",
      "5bb9b8cfd1870534a61ac1ab",
      "5bb9b8e6c04d88316b1f1513",
      "5bb9b904d1870534a61ac494",
      "5bc890cad1870536afb988ff",
      "5bb9b90ad1870534a61ac4f2",
      "5bb9b911d1870534a61ac504",
      "5bc88edcb7db9b3bad18cb77",
      "5bb9b92dd1870534a61ac52e",
      "5bc8592ca6aed865ff8b56dc",
      "5bb9b934c04d88316b1f1704",
      "5bb9b93cd1870534a61ac568",
      "5bffee97b7db9b338d66519f",
    ];
    const items = {};
    _.forEach(menuData, (category) => {
      if (_.includes(categoriesToFilter, category.id)) {
        return;
      }
      category.items.forEach((item) => {
        if (
          (!item.isHidden || item.linkableWhenHidden) &&
          _.some(item.servingOptions, {
            servingOptionId: _.get(servingOptionDetails, "servingOption.id"),
          }) &&
          (item.unavailableInBranches === null ||
            !_.includes(item.unavailableInBranches, _.get(order, "branchId")))
        ) {
          if (_.includes(menuItemIds, item.id)) {
            items[item.id] = {...item, categoryIndex: _.get(category, "index")};
          } else if (item.sizeOptionsIds) {
            item.sizeOptionsIds.forEach((sizeOptionId) => {
              items[sizeOptionId] = {...item, categoryIndex: _.get(category, "index")};
            });
          }
        }
      });
    });

    const itemsInDeal = _.map(
      _.sortBy(
        _.uniq(_.filter(menuItemIds, (itemId) => items[itemId])),
        (itemId) => {
          return (
            _.get(items[itemId], "categoryIndex") +
           " " +
            _.get(items[itemId], "index")
          );
        },
      ),
      (itemId) => items[itemId],
    );

    return (
      <AppContainer.Content
        classNames={styles.AppContainer}
        tightBottom
        appStyles={appStyles}
      >
        <List classNames={styles.GiftOptions}>
          {_.map(itemsInDeal, (item) => (
            <Item
              T={T}
              appStyles={appStyles}
              isMenuItem
              key={item.id}
              description={item.description}
              title={item.name}
              useCategoryImage={item.useCategoryImage}
              onClick={this.onSelectedItem(item)}
              imagePreview={item.imagePreview}
              imageKey={item.imageKey}
              defaultImage={defaultImage}
              classNames={styles.GiftOption}
              loading={this.state.menuItemClicked === item.id}
              imageObjectFit={{
                ...(item.imageObjectFit && {
                  objectFit:
                    item.imageObjectFit === "fit"
                      ? "contain"
                      : item.imageObjectFit,
                }),
              }}
              allergens={_.filter(allergens, (allergen) =>
                _.includes(item.allergens, allergen.id),
              )}
              currencySymbol={currencySymbol}
            />
          ))}
        </List>
      </AppContainer.Content>
    );
  }
}
