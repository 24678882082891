import React from "react";
import withTranslation from "../hocs/withTranslation";
import { connect } from "react-redux";
import GiftView from "../components/GiftView";
import { saveSelectedGiftStep } from "../store/giftRedeem/actions";
import { tradeBankPointsForCoupon } from "../store/user/actions";
import {
  getDealGroup,
  getStyles,
  getServingOptionDetails,
} from "../store/selectors";

const GiftPage = (props) => <GiftView {...props} />;

const mapStateToProps = (state, props) => {
  const { user, header, order, menuItemsConfiguration, giftRedeem } = state;
  return {
    order,
    menuItemsConfiguration,
    user,
    giftRedeem,
    header,
    dealGroup: getDealGroup(state, props),
    appStyles: getStyles(state, props),
    servingOptionDetails: getServingOptionDetails(state, props),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveSelectedGiftStep: (item, menuData) =>
      dispatch(saveSelectedGiftStep(item, menuData)),
    tradeBankPointsForCoupon: (gift) =>
      dispatch(tradeBankPointsForCoupon(gift)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation(GiftPage));
